@use 'variables';

.about-title {
	text-align: left;
	margin-bottom: 2rem;

	& .underline {
		margin-left: 0;
	}
}

.about-center {
	display: grid;
	gap: 3rem 2rem;
}

.about-img {
	justify-self: center;
}

@media screen and (min-width: 992px) {
	.about-center {
		grid-template-columns: 1fr 1fr;
	}

	.about-img {
		position: relative;

		&::before {
			left: -2rem;
		}
	}

	.about-info {
		align-self: center;

		p.contact-info-link {
			ul {
				padding: 15px;

				li {
					display: flex;
					gap: 10px;
					padding: 5px;
				}
			}
		}
	}

	.about-img[data-border]::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		border: 0.25rem solid var(--clr-primary-5);
		top: 2rem;
		right: -2rem;
		border-radius: var(--radius);
	}
}
