@use 'variables';

.nav-links {
	display: none;
}

.nav {
	height: 5rem;
	padding: 1rem;
	display: grid;
	-webkit-box-align: center;
	align-items: center;
	-webkit-transition: var(--transition);
	transition: var(--transition);
	background-color: var(--clr-primary-10);
}

.nav-center {
	width: 100%;
	max-width: 1170px;
	margin: 0 auto;
}

.nav-header {
	display: grid;
	grid-template-columns: auto 1fr;
	-webkit-box-align: center;
	align-items: center;
}

.nav-btn {
	background: transparent;
	border-color: transparent;
	color: var(--clr-primary-5);
	font-size: 2rem;
	cursor: pointer;
	justify-self: end;
}

@media screen and (min-width: 768px) {
	.nav {
		background: var(--clr-primary-10);
	}

	.nav-btn {
		display: none;
	}

	.nav-links {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		justify-items: center;
		-webkit-column-gap: 2rem;
		-moz-column-gap: 2rem;
		column-gap: 2rem;
		justify-self: end;
	}

	.nav-links a {
		text-transform: capitalize;
		color: var(--clr-grey-1);
		font-weight: bold;
		letter-spacing: var(--spacing);
		-webkit-transition: var(--transition);
		transition: var(--transition);
	}

	.nav-links a:hover {
		color: var(--clr-primary-5);
	}

	.nav-center {
		display: grid;
		grid-template-columns: auto 1fr;
		-webkit-box-align: center;
		align-items: center;
	}
}

/* fixed navbar */
.navbar-fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: var(--clr-primary-10);
	z-index: 2;
	box-shadow: var(--light-shadow);
}

.nav-logo {
	width: 3em;
	height: 3em;
	stroke: var(--clr-grey-3);
	transition: var(--transition);

	&:hover {
		stroke: var(--clr-primary-5);
		cursor: pointer;
	}

	path#R {
		fill: var(--clr-grey-3);
		stroke: var(--clr-grey-3);
	}
}
