@use 'variables';

.scroll-tracker-container {
  height: 0.3rem;
  position: fixed;
  inset: 0 0 auto;
  z-index: 1000;
}

.scroll-tracker-style {
  @extend .scroll-tracker-container;
  background-color: var(--clr-primary-5);
}