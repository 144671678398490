@use 'variables';

.footer {
  padding-bottom: 2rem;
  background: #222;
  display: grid;
  place-items: center;
}

.footer .social-icons {
  margin-bottom: 2rem;
}

.footer .social-icon {
  color: var(--clr-primary-5);
}

.footer .social-icon:hover {
  color: var(--clr-primary-10);
}

.footer p {
  text-transform: capitalize;
}