/*
===============
Projects
===============
*/

.project {
  display: grid;
  margin-bottom: 4rem;

  &:hover .project-img::after {
    opacity: 0;
  }
}

.project-img {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  height: 19rem;
  z-index: 1;

  display: inline-block;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, var(--clr-primary-8), var(--clr-grey-5));
    opacity: 0.85;
    transition: var(--transition);
  }
}

.project-info {
  background: var(--clr-white);
  padding: 1rem 2rem;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.project-number {
  display: inline-block;
  font-size: 1.25rem;
  color: var(--clr-primary-5);
  margin-bottom: 0.75rem;
}

.project-slug {
  h3 {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    color: var(--clr-grey-1);
    transition: var(--transition);
  }

  h3:hover {
    color: var(--clr-primary-5);
  }
}

.project-desc {
  color: var(--clr-grey-3);
}

.project-stack {
  margin-bottom: 1rem;
}

.about-stack span {
  margin-top: 0.5rem;
}

.project-stack span,
.about-stack span {
  display: inline-block;
  background: var(--clr-grey-9);
  color: var(--clr-grey-5);
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  font-size: 0.85rem;
}

.project-icon {
  color: var(--clr-primary-5);
  font-size: 1.25rem;
  margin-right: 0.5rem;
  transition: var(--transition);

  &:hover {
    color: var(--clr-primary-1);
  }
}

.project-links {
  a {
    margin-right: 0.7rem;
  }

  a:last-child {
    margin-right: 0;
  }
}

.link {
  color: var(--clr-grey-1);
  font-weight: bold;
  letter-spacing: var(--spacing);
  transition: var(--transition);

  &:hover {
    color: var(--clr-primary-5);
  }
}


@media screen and (min-width: 576px) {
  .project-img {
    height: 19rem;
  }
}

@media screen and (min-width: 768px) {
  .project-img {
    height: 22rem;
  }
}

@media screen and (min-width: 992px) {
  .project {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }

  .project-img {
    grid-column: 1 / span 8;
    grid-row: 1 / 1;
    height: 30rem;
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);
  }

  .project-info {
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);

    z-index: 1;
    grid-column: 5 /12;
    grid-row: 1 / 1;
  }

  .project:nth-of-type(even) .project-img {
    grid-column: 5 / -1;
    grid-row: 1 / 1;
  }

  .project:nth-of-type(even) .project-info {
    grid-column: 2 / span 7;
    grid-row: 1 / 1;
    text-align: left;
  }
}